import axios from "axios";

// 创建axios实例
export const service = axios.create({
  baseURL: "/api/emo",
  // baseURL: "/applet/api/emo",
  timeout: 300000,
  withCredentials: true,
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做些什么，例如添加token等
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 在接收响应数据之前做些什么
    return response.data;
  },
  (error) => {
    // 响应错误处理，可以根据不同的错误状态码做不同的处理
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 处理未授权错误
          break;
        case 404:
          // 处理未找到资源错误
          break;
        default:
          // 处理其他错误
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default {
  // 暴露axios实例
  service,

  // GET请求
  get(url, params) {
    return service.get(url, params);
  },

  // POST请求
  post(url, data) {
    return service.post(url, data);
  },

  put(url, data) {
    return service.put(url, data);
  },

  delete(url) {
    return service.delete(url);
  },
};
