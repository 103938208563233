<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  let lastTouchEnd = 0;
  document.addEventListener(
    "touchend",
    function (event) {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>
<style lang="less">
#app {
  // max-width: 375px;
  height: 100%;
}
body {
  padding-top: env(safe-area-inset-top);
  margin-top: -env(safe-area-inset-top);
}
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
</style>
