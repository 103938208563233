<template>
  <div class="layout">
    <!-- 标题 -->
    <div class="app-haeder">
      <!-- <van-nav-bar :title="company" /> -->
    </div>
    <!-- 主内容输出 -->
    <div class="app-main">
      <router-view></router-view>
    </div>
    <!-- 底部Nav -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      unitId: window.localStorage.getItem("unitId"), //公司id
      company: "", //公司
    };
  },
  mounted() {
    if (this.unitId == "undefined") {
      this.company = "高情商训练营";
    }
    if (this.unitId == "sys1818") {
      this.company = "情绪价值训练营";
    }
    if (this.unitId == "1397") {
      this.company = "高情商训练营";
    }
  },
};
</script>
<style lang="less">
.layout {
  height: 100%;

  .app-main {
    height: calc(~"100% - 46px"); // 除去顶部内容高度
    box-sizing: border-box;
    padding-bottom: 50px;
    //苹果兼容处理
    padding-bottom: calc(50px + constant(safe-area-inset-bottom));
    padding-bottom: calc(50px + env(safe-area-inset-bottom));
  }
}
</style>
